<template>
  <div>
    <v-card tile>
      <v-container data-v-step="search_0">
        <v-text-field
          ref="searchField"
          outlined
          :label="$t('search.search')"
          v-model="searchTerm"
          append-icon="mdi-magnify"
          hide-details
          @click:append="
            () => {
              getSearchResults();
              $refs.searchField.blur();
            }
          "
          @keydown.native.enter="
            () => {
              getSearchResults();
              $refs.searchField.blur();
            }
          "
          :loading="status === 'fetching'"
        ></v-text-field>
      </v-container>
    </v-card>
    <v-tabs
      color="primary"
      v-model="typeIdx"
      grow
      show-arrows
      data-v-step="search_1"
    >
      <v-tab v-for="item in types" :key="item.text">{{ item.text }}</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-container fluid grid-list-md class="pt-0">
      <template>
        <div
          class="text-body-1 py-3"
          v-if="
            searchTerm.length > 0 &&
            items.length < 1 &&
            searchTerm === $route.query.q &&
            status !== 'fetching'
          "
        >
          {{
            $t("search.noResultsFound", {
              type: $t(`search.types.${type}`),
              searchTerm,
            })
          }}
        </div>
      </template>
      <v-subheader class="mt-4" v-if="types[typeIdx].count > 0">{{
        $t("search.totalSearchResults", { total: types[typeIdx].count })
      }}</v-subheader>
      <template v-if="items.length > 0">
        <v-card outlined>
          <movie-list v-if="type === 'film'" :items="items"></movie-list>
          <contentpage-list
            :items="items"
            v-else-if="type === 'contentpage'"
          ></contentpage-list>
          <tv-list :items="tvItems" v-else-if="type === 'tv'"></tv-list>
          <radio-list
            disable-favorite
            :items="items"
            v-else-if="type === 'radio'"
          ></radio-list>
        </v-card>
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import MovieList from "@/components/vod/MovieList";
import ContentpageList from "@/components/ContentpageList";
import TvList from "@/components/TvList";
import RadioList from "@/components/RadioList";

export default {
  components: {
    MovieList,
    ContentpageList,
    TvList,
    RadioList,
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapActions("search", ["getSearchResults", "setType"]),
    ...mapMutations("search", {
      setSearchTerm: "SET_SEARCH_TERM",
    }),
    initData() {
      this.setType({ idx: this.typeIdx, type: this.$route.query["q-type"] });
      this.setSearchTerm(this.$route.query.q);
      if (this.searchTerm.length < 1) {
        this.setType({ idx: 0 });
      }

      this.getSearchResults();
    },
  },
  computed: {
    ...mapGetters("search", ["types", "items", "tvItems", "totalTypeCount"]),
    ...mapState({
      status: (state) => state.search.status,
      type: (state) => state.search.type,
    }),
    searchTerm: {
      set(val) {
        this.setSearchTerm(val);
      },
      get() {
        return this.$store.state.search.searchTerm;
      },
    },
    typeIdx: {
      set(val) {
        this.setType({ idx: val });
      },
      get() {
        return this.$store.state.search.typeIdx;
      },
    },
  },
  watch: {
    searchTerm(val) {
      this.setSearchTerm(val);
    },
  },
};
</script>
